import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as xlsx from "xlsx";
import { AlertService } from '../../../services/alert/alert.service';
import { CategoriaEventosService } from '../../../services/parametros/categoria-eventos/categoria-eventos.service';

@Component({
  selector: 'app-categoria-eventos',
  templateUrl: './categoria-eventos.component.html',
  styleUrls: ['./categoria-eventos.component.scss']
})
export class CategoriaEventosComponent implements OnInit {
  displayedColumns: string[] = ['id', 'categoria', 'estado','opciones'];
  dataSource 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  modalRef?: BsModalRef;
  categoriaSeleccionado
  formGestion: FormGroup

  constructor(private categoriasService:CategoriaEventosService,private alertService:AlertService, private modalService: BsModalService,private _formBuilder: FormBuilder,) { 
this.obtenerCategorias()
  }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
  
  }
  
  obtenerCategorias(){
    this.categoriasService.obtenerCategoriaEventos().subscribe(resp=>{
      if(resp.success){
        this.dataSource = new MatTableDataSource(resp.data);
        this.configurarTabla()
      }else{
          this.alertService.crearMensaje("error",resp.message)
      }
    })
  }
  crearCategoria() {
    this.categoriasService.crearCategoria(this.formGestion.controls.categoria.value.toUpperCase()).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.modalRef.hide()
        this.obtenerCategorias()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }
  editarCategoria() {
    this.categoriasService.editarCategoria(this.categoriaSeleccionado.id,this.formGestion.controls.categoria.value.toUpperCase()).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.modalRef.hide()
        this.obtenerCategorias()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }
  activarInactivarCaregoria(id) {
    this.categoriasService.activarInactivarCategoria(id).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerCategorias()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    }
    )
  }
  gestionarCategoria(template: TemplateRef<any>, c, data) {
    this.openModal(template, c)
    if (data != undefined) {
      this.categoriaSeleccionado=data

    } else {
      this.categoriaSeleccionado=undefined
    }
    this.crearFormulario(data);

  }
  crearFormulario(data) {
    try {
      this.formGestion = this._formBuilder.group({
        categoria: [data.categoria, [Validators.required]],
    
      }
      );
    }catch{
      this.formGestion = this._formBuilder.group({
        categoria: ["", [Validators.required]],
     
      }
      );
    }
   

  }
  configurarTabla() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Código del evento": data[key].id,
        "Categoria del evento": data[key].categoria,
        "Estado": data[key].estado,

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_CATEGORIA-EVENTOS_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }
  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }
}
