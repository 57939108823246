import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../../properties';

@Injectable({
  providedIn: 'root'
})
export class LugaresService {

  constructor(private http:HttpClient,private router:Router) { }

  obtenerUbicacionEvento():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/parametros-eventos/obtener-sitios-eventos',{ headers: headers })
  }

  obtenerUbicacion():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/lugares/listar-lugares',{ headers: headers })
  }
  crearLugar(nombre_lugar,capacidad):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "nombre_lugar"     :nombre_lugar,
        "capacidad"        :capacidad
    }
    return this.http.post(Properties.URL+'api/lugares/crear-lugar',raw,{ headers: headers })
  }

  activarInactivarLugar(id):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_lugar":id
      }

    return this.http.post(Properties.URL+'api/lugares/activar-inactivar-lugar',raw,{ headers: headers })
  }

  editarLugar(id,nombre_lugar,capacidad):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_lugar":id,
        "nombre_lugar"     :nombre_lugar,
        "capacidad"        :capacidad
      }

    return this.http.post(Properties.URL+'api/lugares/editar-lugar',raw,{ headers: headers })
  }


}
