import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient,private router:Router) { }
  almacenarToken(token){
    localStorage.setItem(Properties.VAR_TOKEN_ADMIN,token)
  }
  obtenerToken(){
    try{
      return localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

    }catch {
      return null
    }
  }
  autenticacion(email,clave):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
   let raw={
     email:email,
     clave:clave
   }
   return this.http.post(Properties.URL+'api/usuarios/autenticacion',raw,{ headers: headers })
  }
  recordarClave(email):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
   let raw={
     email:email,
   }
   return this.http.post(Properties.URL+'api/usuarios/recordar-clave',raw,{ headers: headers })
  }
  cambiarClave(email,otp,clave):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        //"AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
   let raw={
    "email":email,
    "otp"  :otp,
    "clave":clave   }
   return this.http.post(Properties.URL+'api/usuarios/cambiar-clave',raw,{ headers: headers })
  }
  cerrarSesion(){
    this.router.navigateByUrl("login")

    localStorage.removeItem(Properties.VAR_TOKEN_ADMIN)
    localStorage.removeItem(Properties.VAR_LOGIN)
  }
  
  registrarse(){

  }

  validarToken():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/usuarios/validar-token',{ headers: headers })

  }
  obtenerInformacionUsuario():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/usuarios/obtener-datos-usuario',{ headers: headers })

  }
}
