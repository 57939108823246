import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavegacionService {

  constructor(private router:Router) { }

  navegarInicio(){
    this.router.navigateByUrl("admin")
  }
  navegarEventos(){
    this.router.navigateByUrl("admin/eventos")
  }
  navegarMetricas(){
    this.router.navigateByUrl("admin/metricas")
  }
  navegarUsuarios(){
    this.router.navigateByUrl("admin/usuarios")
  }
  navegarLog(){
    this.router.navigateByUrl("admin/log")
  }
  navegarDependencias(){
    this.router.navigateByUrl("admin/parametros/dependencias")
  }
  navegarAreas(){
    this.router.navigateByUrl("admin/parametros/areas")
  }
  navegarFacultad(){
    this.router.navigateByUrl("admin/parametros/facultad")
  }
  navegarPrograma(){
    this.router.navigateByUrl("admin/parametros/programa")
  }
  navegarEstadoParticipante(){
    this.router.navigateByUrl("admin/parametros/estado-participantes")
  }
  navegarEstadoEvento(){
    this.router.navigateByUrl("admin/parametros/estado-evento")
  }
  navegarDocumentos(){
    this.router.navigateByUrl("admin/parametros/documentos-legales")
  }
  navegarLugares(){
    this.router.navigateByUrl("admin/parametros/lugares")
  }
  navegarCategoriaEventos(){
    this.router.navigateByUrl("admin/parametros/categoria-eventos")
  }
  navegarTipoEventos(){
    this.router.navigateByUrl("admin/parametros/tipo-eventos")
  }

  navegarRoles(){
    this.router.navigateByUrl("admin/parametros/roles")

  }
  navegarOpcionesEventos(registro){
    //console.log(registro)
    this.router.navigateByUrl("admin/ver-detalle-evento",{ state: registro })
  }

  navegarGestionEvento(registro,intencion){
    this.router.navigateByUrl("admin/gestion-evento",{ state: {evento:registro,intencion:intencion} })
  }

  navegarRegistro(){
    this.router.navigateByUrl("registro")

  }
  navegarLogin(){
    this.router.navigateByUrl("login")

  }
  navegarOlvideClave(){
    this.router.navigateByUrl("olvide-clave")

  }
}
