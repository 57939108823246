import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsModalRef, BsLocaleService, BsModalService } from 'ngx-bootstrap';
import * as xlsx from "xlsx";
import { AlertService } from '../../../services/alert/alert.service';
import { LugaresService } from '../../../services/parametros/lugares/lugares.service';

@Component({
  selector: 'app-lugares',
  templateUrl: './lugares.component.html',
  styleUrls: ['./lugares.component.scss']
})
export class LugaresComponent implements OnInit {
  displayedColumns: string[] = ['id','nombre_aula', 'capacidad', 'estado', 'op'];
  modalRef?: BsModalRef;
  dataSource= new MatTableDataSource( );
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  formGestion: FormGroup

  lugarSeleccionado
  constructor(private localeService: BsLocaleService, private _formBuilder: FormBuilder, private modalService: BsModalService, private lugaresService: LugaresService, private alertService: AlertService) {
    this.obtenerLugares()
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  obtenerLugares() {
    this.lugaresService.obtenerUbicacion().subscribe(resp => {
      if (resp.success) {
        //console.log(resp.data)
        let i=0
        resp.data.forEach(r=>{
          resp.data[i].capacidad=r.capacidad+""
          resp.data[i].created_at=""
          resp.data[i].updated_at=""

          i++
        })

        this.dataSource = new MatTableDataSource(resp.data);
        
        this.configurarTabla()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }
  crearLugar() {
    this.lugaresService.crearLugar(this.formGestion.controls.nombre.value.toUpperCase(),Number(this.formGestion.controls.capacidad.value) ).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.modalRef.hide()
        this.obtenerLugares()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }

  editarLugar() {
    this.lugaresService.editarLugar(this.lugarSeleccionado.id,this.formGestion.controls.nombre.value.toUpperCase(), Number(this.formGestion.controls.capacidad.value)).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.modalRef.hide()
        this.obtenerLugares()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }

  
  activarInactivarLugar(id) {
    this.lugaresService.activarInactivarLugar(id).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerLugares()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    }
    )
  }

  gestionarLugar(template: TemplateRef<any>, c, data) {
    this.openModal(template, c)
    if (data != undefined) {
      this.lugarSeleccionado=data

    } else {
      this.lugarSeleccionado=undefined
    }
    this.crearFormulario(data);

  }

  configurarTabla() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
      //console.log(event,this.dataSource.filter)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  crearFormulario(data) {
    try {
      this.formGestion = this._formBuilder.group({
        nombre: [data.nombre_aula, [Validators.required]],
        capacidad: [data.capacidad, [Validators.required]],
      }
      );
    }catch{
      this.formGestion = this._formBuilder.group({
        nombre: ["", [Validators.required]],
        capacidad: ["", [Validators.required]],
      }
      );
    }
   

  }
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Nombre del Lugar": data[key].nombre_aula,
        "Capacidad o Aforo": data[key].capacidad,
        "Estado": data[key].estado,
      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_LUGARES_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }
  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }

 controlnumero(){
  this.formGestion.controls.capacidad.setValue(this.formGestion.controls.capacidad.value.replace(/[^0-9.]*/g, ''))

 }
}

