export class Properties {

  static VERSION = '2.0.0';

  static URL='https://18ji333yvh.execute-api.us-east-1.amazonaws.com/'
  static APPNAME = 'ADMIN'
  static APITOKEN='2FB971D54EAE93C45B7EE69EC0A117422CCB6052'
  static APIKEY='82BBBB4884243BFE341E1D9C493C6A21F7167D7B6DF03B7AA626933EED6748EC'

  static RECORDARLOGIN='recordarAdminBienestar'
  static VAR_COCKIES_EMAIL='emailBienestar'
  static VAR_TOKEN_ADMIN='adminBienestarToken'
  static VAR_LOGIN='adminBienestarLogin'

}
