import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { BsModalRef, listLocales, BsLocaleService, BsModalService, defineLocale, esLocale } from 'ngx-bootstrap';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import * as xlsx from "xlsx";
import { LogService } from '../../services/log/log.service';
import { AlertService } from '../../services/alert/alert.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  displayedColumns: string[] = ['idLog', 'primer_nombre', 'nivel_log', 'fecha_log', 'idEvento', 'contenido'];
  dataSource: MatTableDataSource<any>;
  modalRef?: BsModalRef;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  locale = 'es';
  locales = listLocales();

  
  constructor(private navegacion: NavegacionService,  private ngxUiLoaderService: NgxUiLoaderService,private localeService: BsLocaleService,private modalService: BsModalService,private logService:LogService,private alertService:AlertService,private eventoService:EventosService) {
    // Create 100 users

    // Assign the data to the data source for the table to render
    this.obtenerLogs()
    defineLocale("es",esLocale)

  }



  ngOnInit() {
    this.localeService.use(this.locale);

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  
  obtenerLogs() {
    this.ngxUiLoaderService.start()

    this.logService.obtenerLogs().subscribe(resp => {
      if (resp.success) {
        this.dataSource = new MatTableDataSource(resp.data);
        this.configurarTabla()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    })
  }
  configurarTabla() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  verDetalle(registro) {
    this.navegacion.navegarOpcionesEventos(registro)
  }

  onFileSelected(event) {
   let i=0
    Array.prototype.forEach.call(event.target.files,function(file) {
      i++
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        //console.log(i);
      };

      });
   
   
  }
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-sm' })
    );
  }
  
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Código": data[key].idLog,
        "Usuario": data[key].primer_nombre + " " + data[key].primer_apellido,
        "Accion": data[key].nivel_log,
        "Fecha y hora de la Acción": data[key].fecha_log,
        "Evento": data[key].idEvento,
        "Detalle": data[key].contenido,

       

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_LOG_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }
 
  
  verEvento(registro) {
    this.ngxUiLoaderService.start()

    this.eventoService.obtenerEvento(registro.idEvento).subscribe(resp=>{
      if(resp.success){
        this.navegacion.navegarOpcionesEventos(resp.data)
      }else{
          this.alertService.crearMensaje("error",resp.message)
      }
      this.ngxUiLoaderService.stop()


    })
  }

}