import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class DocumentosLegalesService {

  constructor(private http: HttpClient) { }
  obtenerDocumentos(): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    return this.http.get(Properties.URL + 'api/documentos-legales/listar-documentos', { headers: headers })
  }
  crearDocumento(nombre_documento, descripcion, version, base64_documento,tipo): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });

    let raw = {
      "nombre_documento": nombre_documento,
      "descripcion": descripcion,
      "version": version,
      "base64_documento": base64_documento,
      "tipo":tipo
    }
    return this.http.post(Properties.URL + 'api/documentos-legales/crear-documento', raw, { headers: headers })

  }
}
