import { Routes } from '@angular/router';

// Layouts
import { EventosComponent } from './components/eventos/eventos.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { MetricasComponent } from './components/metricas/metricas.component';
import { AreasComponent } from './components/parametros/areas/areas.component';
import { RolesComponent } from './components/parametros/roles/roles.component';
import { DependenciaComponent } from './components/parametros/dependencia/dependencia.component';
import { FacultadComponent } from './components/parametros/facultad/facultad.component';
import { ProgramaComponent } from './components/parametros/programa/programa.component';
import { EstadoParticipantesComponent } from './components/parametros/estado-participantes/estado-participantes.component';
import { EstadoEventoComponent } from './components/parametros/estado-evento/estado-evento.component';
import { DocumentosLegalesComponent } from './components/parametros/documentos-legales/documentos-legales.component';
import { LugaresComponent } from './components/parametros/lugares/lugares.component';
import { CategoriaEventosComponent } from './components/parametros/categoria-eventos/categoria-eventos.component';
import { TipoEventosComponent } from './components/parametros/tipo-eventos/tipo-eventos.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { RegistroDatosComponent } from './components/auth/registro-datos/registro-datos.component';
import { LogComponent } from './components/log/log.component';
import { AuthGuard } from './guards/auth.guard';
import { OlvideClaveComponent } from './components/auth/olvide-clave/olvide-clave.component';
import { EventosOpcionesComponent } from './components/eventos-opciones/eventos-opciones.component';
import { GestionEventoComponent } from './components/gestion-evento/gestion-evento.component';
import { Landing } from './@pages/layouts/landing/landing.component';


export const AppRoutes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "olvide-clave", component: OlvideClaveComponent },

  {
    path: "registro", children:
      [{ path: "cuenta", component: RegistroComponent },
      { path: "datos", component: RegistroDatosComponent },
      
      { path: '**', redirectTo: 'cuenta' },

 ]
  },


  {
    path: 'admin',
    component: Landing,
    canActivateChild: [AuthGuard],

    children: [
      { path: "", component: InicioComponent },
      { path: "eventos", component: EventosComponent },
      { path: "ver-detalle-evento", component: EventosOpcionesComponent },
      { path: "gestion-evento", component: GestionEventoComponent },

      { path: "metricas", component: MetricasComponent },
      { path: "usuarios", component: UsuariosComponent },
      { path: "log", component: LogComponent },

      {
        path: "parametros", children: [
          { path: "roles", component: RolesComponent },
          { path: "dependencias", component: DependenciaComponent },
          { path: "areas", component: AreasComponent },
          { path: "facultad", component: FacultadComponent },
          { path: "programa", component: ProgramaComponent },
          { path: "estado-participantes", component: EstadoParticipantesComponent },
          { path: "estado-evento", component: EstadoEventoComponent },
          { path: "documentos-legales", component: DocumentosLegalesComponent },
          { path: "lugares", component: LugaresComponent },
          { path: "categoria-eventos", component: CategoriaEventosComponent },
          { path: "tipo-eventos", component: TipoEventosComponent },
          




        ]
      }

    ],
  },
  { path: '**', redirectTo: 'login' },

  // Executive
];
