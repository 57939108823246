// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { LOCALE_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import {MatStepperModule} from '@angular/material/stepper';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs, 'es');
import { ChartsModule } from 'ng2-charts';

import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  DatePipe,
} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { NgxResponsiveStackTableModule } from 'ngx-responsive-stack-table';
import { FlexLayoutModule } from '@angular/flex-layout';

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Layouts
// Layout Service - Required

// Shared Layout Components
import { SharedModule } from './@pages/components/shared.module';

import { NgOtpInputModule } from 'ng-otp-input';
import { ImageUploadModule } from 'angular2-image-upload';
import { CookieService } from 'ngx-cookie-service';

// Basic Bootstrap Modules
import {
  BsDropdownModule,
  AccordionModule,
  AlertModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
} from 'ngx-bootstrap';

// Thirdparty Components / Plugins - Optional
import { NvD3Module } from 'ngx-nvd3';
import { NgxEchartsModule } from 'ngx-echarts';
import { IsotopeModule } from 'ngx-isotope';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CurrencyMaskDirective } from './directive/currency-mask.directive';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';

// Service - Demo content - Optional

// Social Page - Optional

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// Dashboard Widgets - Optional
import { DashboardModule } from './components/dashboard/dashboard.module';

// Dashboards - Optional
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { NgxCurrencyModule } from 'ngx-currency';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CopyPasteDisabledDirective } from './directive/copy-paste-disabled.directive';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import {
  NgbPaginationModule,
  NgbAlertModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';

import {
  MatInputModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatButtonModule,
  MatFormFieldModule,
  MatRippleModule,
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  
  MatCheckboxModule,
  MatTabsModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { NgxMaskModule, IConfig } from 'ngx-mask'

import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

import { NgApexchartsModule } from 'ng-apexcharts';

import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';

import { FileUploadModule } from 'ng2-file-upload';
import { WebcamModule } from 'ngx-webcam';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';

import { EditorModule } from '@tinymce/tinymce-angular';

import { NgxUploaderModule } from 'ngx-uploader';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { NgxAnimationsModule } from 'ngx-animations';
import { LettersOnlyDirective } from './directive/letters-only.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InicioComponent } from './components/inicio/inicio.component';
import { EventosComponent } from './components/eventos/eventos.component';
import { MetricasComponent } from './components/metricas/metricas.component';
import { RolesComponent } from './components/parametros/roles/roles.component';
import { DependenciaComponent } from './components/parametros/dependencia/dependencia.component';
import { AreasComponent } from './components/parametros/areas/areas.component';
import { FacultadComponent } from './components/parametros/facultad/facultad.component';
import { ProgramaComponent } from './components/parametros/programa/programa.component';
import { EstadoParticipantesComponent } from './components/parametros/estado-participantes/estado-participantes.component';
import { EstadoEventoComponent } from './components/parametros/estado-evento/estado-evento.component';
import { DocumentosLegalesComponent } from './components/parametros/documentos-legales/documentos-legales.component';
import { LugaresComponent } from './components/parametros/lugares/lugares.component';
import { CategoriaEventosComponent } from './components/parametros/categoria-eventos/categoria-eventos.component';
import { TipoEventosComponent } from './components/parametros/tipo-eventos/tipo-eventos.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RegistroComponent } from './components/auth/registro/registro.component';
import { RegistroDatosComponent } from './components/auth/registro-datos/registro-datos.component';
import { LogComponent } from './components/log/log.component';
import { EventosOpcionesComponent } from './components/eventos-opciones/eventos-opciones.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AlertService } from './services/alert/alert.service';
import { OlvideClaveComponent } from './components/auth/olvide-clave/olvide-clave.component';
import { GestionEventoComponent } from './components/gestion-evento/gestion-evento.component';
import { AlfaNumericOnlyDirective } from './directive/alfanumeric.directive';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { Landing } from './@pages/layouts/landing/landing.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const ngxUiLoaderConfig : NgxUiLoaderConfig=
{
  "bgsColor": "#70c9c0",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fgsColor": "rgb(247,194,60)",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-scale-multiple",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 200,
  "logoUrl": "assets/img/colmayor-1.svg",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgb(0,0,0)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

// Hammer Config Overide
// https://github.com/angular/angular/issues/10541
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}


@NgModule({
  declarations: [
    AppComponent,

    InicioComponent,
    EventosComponent,
    MetricasComponent,
    RolesComponent,
    DependenciaComponent,
    AreasComponent,
    FacultadComponent,
    ProgramaComponent,
    EstadoParticipantesComponent,
    EstadoEventoComponent,
    DocumentosLegalesComponent,
    LugaresComponent,
    CategoriaEventosComponent,
    TipoEventosComponent,
    UsuariosComponent,
    LoginComponent,
    AlfaNumericOnlyDirective,
    RegistroComponent,
    RegistroDatosComponent,
    LogComponent,
    EventosOpcionesComponent,
    NumbersOnlyDirective,
    OlvideClaveComponent,
    GestionEventoComponent,
    Landing,
  ],

  imports: [
    FileUploadModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    ChartsModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AngularFileUploaderModule,
    NgxUploaderModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbModule,
    NgxResponsiveStackTableModule,
    NgApexchartsModule,
    MatTableModule,
    MatSelectModule,
    MatPaginatorModule,
    MatMenuModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatCardModule,
    MatSortModule,
    MatRadioModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatBottomSheetModule,
    MatSidenavModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    MatIconModule,
    MatTooltipModule,
    MatTabsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    BrowserModule,
    WebcamModule,
    ImageUploadModule.forRoot(),
    PdfViewerModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    SharedModule,
    NgOtpInputModule,
    SweetAlert2Module.forRoot(),
    SweetAlert2Module,
    RouterModule.forRoot(AppRoutes),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    EditorModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,

    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    ImageUploadModule.forRoot(),
    FileUploadModule,
    ToastrModule.forRoot({ timeOut: 10000 }),
    NvD3Module,
    NgxEchartsModule,
    IsotopeModule,
    NgxDnDModule,
    QuillModule.forRoot(),
    PerfectScrollbarModule,
    DashboardModule,
    MatCheckboxModule,

    NgxCurrencyModule,
    CarouselModule.forRoot(),
    ReactiveFormsModule,
    MatGridListModule,
    NgxAnimationsModule,
    LayoutModule,
    PdfJsViewerModule,
    BsDatepickerModule.forRoot(),
 
  ],

  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DecimalPipe,
    AlertService,
  
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    MatDatepickerModule,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },

    DatePipe,
    MatDatepickerModule,  
    CookieService

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
