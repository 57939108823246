import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Alert } from 'selenium-webdriver';
import * as xlsx from "xlsx";
import { AlertService } from '../../../services/alert/alert.service';
import { DependenciaService } from '../../../services/dependencia/dependencia.service';

@Component({
  selector: 'app-dependencia',
  templateUrl: './dependencia.component.html',
  styleUrls: ['./dependencia.component.scss']
})
export class DependenciaComponent implements OnInit {
  displayedColumns: string[] = ['id', 'dependencia'];
  dataSource 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  constructor(private dependenciaService:DependenciaService,private alertService:AlertService) { 
    this.obtenerDependencias()
  }

  ngOnInit() {
  }
  
  
  ngAfterViewInit() {
  
  }

  obtenerDependencias(){
    this.dependenciaService.obtenerDependencias().subscribe(resp=>{
      if(resp.success){
        this.dataSource = new MatTableDataSource(resp.data);
        this.configurarTabla()
      }else{
          this.alertService.crearMensaje("error",resp.message)
      }
    })
  }

  configurarTabla(){

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del '+ Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length+ ' registros';
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Código": data[key].id,
        "Dependencia": data[key].dependencia,

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_DEPENDENCIA_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }
}
