import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private httpClient: HttpClient) { }

  obtenerEventos(categoria, fecha_final, fecha_inicio, estado): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    let raw = {

      "categoria": categoria,
      fecha_inicio: fecha_inicio,
      "fecha_final": fecha_final,
      "estado": estado

    }
    return this.httpClient.post(Properties.URL + "api/eventos/listar-eventos", raw, { headers: headers })

    // return this.httpClient.get(Properties.URL + "api/eventos/listar-eventos", { headers: headers })

  }
  obtenerEvento(id_evento): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      "id_evento": id_evento,


    }
    return this.httpClient.post(Properties.URL + "api/eventos/obtener-detalles-evento", raw, { headers: headers })
  }
  editarEventos(id_evento, cod_evento, nombre, descripcion_breve, descripcion_general, url_informacion_adicional, url_videos, url_encuesta, aforo, precio, fecha_hora_inicio, fecha_hora_fin, sitios, categoria_eventos, tipo_evento, protocolo_bioseguridad): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      id_evento: id_evento,
      cod_evento: cod_evento,
      nombre: nombre,
      descripcion_breve: descripcion_breve,
      descripcion_general: descripcion_general,
      url_informacion_adicional: url_informacion_adicional,
      url_videos: url_videos,
      url_encuesta: url_encuesta,
      aforo: aforo,
      precio: precio,
      fecha_hora_inicio: fecha_hora_inicio,
      fecha_hora_fin: fecha_hora_fin,
      sitios: sitios,
      categoria_eventos: categoria_eventos,
      tipo_evento: tipo_evento,
      protocolo_bioseguridad: protocolo_bioseguridad
    }

    return this.httpClient.post(Properties.URL + "api/eventos/actualizar-evento", raw, { headers: headers })
  }

  crearEventos(cod_evento, nombre, descripcion_breve, descripcion_general, url_informacion_adicional, url_videos, url_encuesta, aforo, precio, fecha_hora_inicio, fecha_hora_fin, sitios, categoria_eventos, tipo_evento, protocolo_bioseguridad): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      cod_evento: cod_evento,
      nombre: nombre,
      descripcion_breve: descripcion_breve,
      descripcion_general: descripcion_general,
      url_informacion_adicional: url_informacion_adicional,
      url_videos: url_videos,
      url_encuesta: url_encuesta,
      aforo: aforo,
      precio: precio,
      fecha_hora_inicio: fecha_hora_inicio,
      fecha_hora_fin: fecha_hora_fin,
      sitios: sitios,
      categoria_eventos: categoria_eventos,
      tipo_evento: tipo_evento,
      protocolo_bioseguridad: protocolo_bioseguridad
    }

    return this.httpClient.post(Properties.URL + "api/eventos/crear-evento", raw, { headers: headers })
  }

  cargarImagen(id, nombre, base64): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

    let raw = {
      "id_evento": id,
      "nombre": nombre,
      "base64_imagen": base64
    }
    return this.httpClient.post(Properties.URL + "api/eventos/cargar-imagenes", raw, { headers: headers })
  }

  eliminarImagen(id_evento, id_imagen): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });


    let raw = {
      "id_evento": id_evento,
      "id_imagen": id_imagen
    }

    return this.httpClient.post(Properties.URL + "api/eventos/eliminar-imagenes", raw, { headers: headers })

  }

  notificarUsuario(id_evento, dni,mensaje): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {
      "id_evento": id_evento + "",

      "dni": dni,
      "mensaje": mensaje
    }
    return this.httpClient.post(Properties.URL + 'api/notificacion/notificar-usuario-especifico', raw, { headers: headers })
  }

  notificarUsuarios(id_evento,mensaje): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {
      "id_evento": id_evento,
    "mensaje" :mensaje
    }
    return this.httpClient.post(Properties.URL + 'api/notificacion/notificar-usuarios-evento', raw, { headers: headers })
  }

  desvincularUsuario(evento,usuario):Observable<any>  {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {
      "id_evento": evento,
      "dni"            : usuario.dni
    }
    return this.httpClient.post(Properties.URL + 'api/eventos/desvincular-usuario', raw, { headers: headers })
    }

    exportExcel(fecha_inicial,fecha_final,criterio):Observable<any>  {
      let headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          "API-NAME": Properties.APPNAME,
          "API-TOKEN": Properties.APITOKEN,
          "API-KEY": Properties.APIKEY,
          "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
        });
      let raw = {
        "fecha_inicial": fecha_inicial,
        "fecha_final"            : fecha_final,
        "criterio"            : criterio
      }
      return this.httpClient.post('http://3.212.117.247:8090/backend-api-cma/public/api/eventos/reportes/listarReportes', raw, { headers: headers })
     
    }
}
