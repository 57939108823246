import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit {
  form: FormGroup
  constructor(private navegacion: NavegacionService, private _formBuilder: FormBuilder, private authService: AuthService, private alertService: AlertService, private _cookieService: CookieService,) {
    this.form = this._formBuilder.group({
      dni: ['', [Validators.required, Validators.email]],
  
    }
    );
  }
  ngOnInit() {
  }

  registro() {
    
  }

}
