import { Component } from '@angular/core';
import { Properties } from './properties';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

declare let gtag: Function;
declare let fbq: Function;

import {
  SwPush,
  SwUpdate,
  UpdateAvailableEvent,
} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app works!';
  message;
  constructor(
   
  ) {
 
  }
  ngOnInit() {
    
   
  }

}
