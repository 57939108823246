import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[alfanumeric]'
})
export class AlfaNumericOnlyDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value=initalValue.replace(/[^A-Za-zÁÉÍÓÚáéíóú0-9\s]*/g, '');


    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}