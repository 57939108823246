import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {


  constructor(private http: HttpClient) { }
  obtenerUsuarios(): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {}
    return this.http.post(Properties.URL + 'api/usuarios-admin/listar-usuarios', raw, { headers: headers })
  }
  asociarUsuario(dni): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = { dni: dni }
    return this.http.post(Properties.URL + 'api/usuarios-admin/asociar-usuario', raw, { headers: headers })
  }
  desasociarUsuario(dni): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = { dni: dni }
    return this.http.post(Properties.URL + 'api/usuarios-admin/desasociar-usuario', raw, { headers: headers })
  }
  activarInactivar(dni): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = { dni: dni }
    return this.http.post(Properties.URL + 'api/usuarios-admin/activar-inactivar-usuario', raw, { headers: headers })
  }
}
