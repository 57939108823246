import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../../properties';

@Injectable({
  providedIn: 'root'
})
export class TipoEventosService {

  constructor(private http:HttpClient,private router:Router) { }


  obtenerTipoEventosGestion():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    return this.http.get(Properties.URL+'api/parametros-eventos/obtener-tipos-evento',{ headers: headers })
  }

  obtenerTipoEventos():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    return this.http.get(Properties.URL+'api/tipos-evento/listar-tipos-evento',{ headers: headers })
  }
  
  crearTipo(categoria):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "tipo":categoria
    }
    return this.http.post(Properties.URL+'api/tipos-evento/crear-tipos-evento',raw,{ headers: headers })
  }

  activarInactivarTipo(id):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_tipo_evento":id
      }

    return this.http.post(Properties.URL+'api/tipos-evento/activar-inactivartipos-evento',raw,{ headers: headers })
  }

  editarTipo(id,categoria):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_tipo_evento":id,
        "tipo":categoria
      }

    return this.http.post(Properties.URL+'api/tipos-evento/editar-tipos-evento',raw,{ headers: headers })
  }

}
