import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { Console } from 'console';
import { defineLocale } from 'moment';
import { esLocale } from 'ngx-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from '../../services/alert/alert.service';
import { ErrorService } from '../../services/error/error.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { CategoriaEventosService } from '../../services/parametros/categoria-eventos/categoria-eventos.service';
import { EstadoEventosService } from '../../services/parametros/estado-eventos/estado-eventos.service';
import { LugaresService } from '../../services/parametros/lugares/lugares.service';
import { TipoEventosService } from '../../services/parametros/tipo-eventos/tipo-eventos.service';

@Component({
  selector: 'app-gestion-evento',
  templateUrl: './gestion-evento.component.html',
  styleUrls: ['./gestion-evento.component.scss']
})
export class GestionEventoComponent implements OnInit {
  @ViewChild("breveEditor", { static: false }) breveEditor: EditorComponent
  @ViewChild("generalEditor", { static: false }) generalEditor: EditorComponent
  @ViewChild("protocoloEditor", { static: false }) protocoloEditor: EditorComponent
  @ViewChild("timeInicio", { static: false }) timeInicio
  formGestion: FormGroup
  categoriaEventos = []
  ubicaciones = []
  estadoEventos = []
  tipoEventos = []
  eventoSeleccionado
  archivos = []
  id_evento

  titulo
  ubicacionSeleccionada
  fechaMinima1 = new Date()
  fechaMinima2 = new Date()
  aux
  horaInicio = '22:00 pm'
  horaFin = '22:00 pm'

  validFile = ["image/png", "image/jpeg", "image/jpg"]
  costo
  estadoActual
  camposActualizables = []

  cod_evento_editable = false
  nombre_editable = false
  sitios_editable = false
  categoria_eventos_editable = false
  tipo_evento_editable = false
  fecha_hora_inicio_editable = false
  fecha_hora_fin_editable = false
  descripcion_breve_editable = false
  descripcion_general_editable = false
  aforo_editable = false
  protocolo_bioseguridad_editable = false
  url_videos_editable = false
  url_encuesta_editable = false
  otros_detalles_editable = false
  url_informacion_adicional_editable = false
  precio_editable = false
  flagClonar = false
  constructor(private router: Router,private errorService:ErrorService, private ngxUiLoaderService: NgxUiLoaderService, private _formBuilder: FormBuilder, private eventoService: EventosService, private alertService: AlertService, private lugarService: LugaresService, private categoriaService: CategoriaEventosService, private tipoService: TipoEventosService, private estadoEventosService: EstadoEventosService, private navegacionService: NavegacionService) {
    this.gotoTop()

    this.fechaMinima1.setDate(this.fechaMinima1.getDate())
    this.fechaMinima1.setMinutes(this.fechaMinima1.getMinutes() + 60)
    this.fechaMinima2.setHours(this.fechaMinima2.getHours() + 1)

    this.obtenerCategoriaEventos()
    this.obtenerEstadoEventos()
    this.obtenerTipoEventos()
    this.obtenerUbicaciones()
    this.crearFormulario(undefined)


        try {
    if (this.router.getCurrentNavigation().extras.state != undefined) {

      this.eventoSeleccionado = this.router.getCurrentNavigation().extras.state.evento
      //console.log(this.eventoSeleccionado)
      this.titulo = "Editar Evento - " + this.eventoSeleccionado.nombre
      this.estadoActual = this.eventoSeleccionado.estados[this.eventoSeleccionado.estados.length - 1]

      this.camposActualizables = this.estadoActual.campos_editables.split(",")
      //console.log(this.camposActualizables)

      //console.log(this.estadoActual)
      if (this.camposActualizables.find(x => x === "cod_evento") != undefined) {
        this.cod_evento_editable = true
        this.formGestion.controls.codigo.enable()

      } else {
        this.formGestion.controls.codigo.disable()
      }
      if (this.camposActualizables.find(x => x === "nombre") != undefined) {
        this.nombre_editable = true
        this.formGestion.controls.nombre.enable()

      } else {
        this.formGestion.controls.nombre.disable()

      }
      if (this.camposActualizables.find(x => x === "sitios") != undefined) {
        this.sitios_editable = true
        this.formGestion.controls.ubicacion.enable()

      } else {
        this.formGestion.controls.ubicacion.disable()

      }
      if (this.camposActualizables.find(x => x === "categoria_eventos") != undefined) {
        this.categoria_eventos_editable = true
        this.formGestion.controls.categoria.enable()

      } else {
        this.formGestion.controls.categoria.disable()

      }
      if (this.camposActualizables.find(x => x === "tipo_evento") != undefined) {
        this.tipo_evento_editable = true
        this.formGestion.controls.tipo.enable()

      } else {
        this.formGestion.controls.tipo.disable()

      }
      if (this.camposActualizables.find(x => x === "fecha_hora_inicio") != undefined) {
        this.fechaMinima1.setMonth(this.fechaMinima1.getMonth() - 12)
        this.fecha_hora_inicio_editable = true
      } else {
        this.fechaMinima1.setMonth(this.fechaMinima1.getMonth() - 12)



      }
      if (this.camposActualizables.find(x => x === "fecha_hora_fin") != undefined) {
        this.fechaMinima2.setMonth(this.fechaMinima2.getMonth() - 11)


        this.fecha_hora_fin_editable = true
      } else {
        this.fechaMinima2.setMonth(this.fechaMinima2.getMonth() - 11)

      }
      if (this.camposActualizables.find(x => x === "descripcion_breve") != undefined) {
        this.descripcion_breve_editable = true

      } else {

      }
      if (this.camposActualizables.find(x => x === "descripcion_general") != undefined) {
        this.descripcion_general_editable = true

      } else {

      }
      if (this.camposActualizables.find(x => x === "aforo") != undefined) {
        this.aforo_editable = true
        this.formGestion.controls.aforo.enable()
      } else {
        this.formGestion.controls.aforo.disable()

      }
      if (this.camposActualizables.find(x => x === "protocolo_bioseguridad") != undefined) {
        this.protocolo_bioseguridad_editable = true

      } else {

      }
      if (this.camposActualizables.find(x => x === "url_videos") != undefined) {
        this.url_videos_editable = true
        this.formGestion.controls.video.enable()

      } else {
        this.formGestion.controls.video.disable()

      }
      if (this.camposActualizables.find(x => x === "url_encuesta") != undefined) {
        this.url_encuesta_editable = true
        this.formGestion.controls.encuesta.enable()

      } else {
        this.formGestion.controls.encuesta.disable()

      }
      if (this.camposActualizables.find(x => x === "otros_detalles") != undefined) {
        this.url_informacion_adicional_editable = true
        this.formGestion.controls.adicional.enable()

      } else {
        this.formGestion.controls.adicional.disable()

      }
      if ((this.camposActualizables.find(x => x === "informacion_adicional") != undefined) || (this.camposActualizables.find(x => x === "url_informacion_adicional") != undefined) ) {
        this.url_informacion_adicional_editable = true
        this.formGestion.controls.adicional.enable()

      } else {
        this.formGestion.controls.adicional.disable()

      }
      if (this.camposActualizables.find(x => x === "precio") != undefined) {
        this.precio_editable = true
        this.formGestion.controls.costo.enable()

      } else {
        this.formGestion.controls.costo.disable()

      }
      this.obtenerEvento()

      //console.log(this.router.getCurrentNavigation().extras.state.intencion)
      if (this.router.getCurrentNavigation().extras.state.intencion === "clonar") {
        this.titulo = "Clonar Evento - " + this.eventoSeleccionado.nombre
        this.flagClonar = true
        this.habilitarCampo()
      }

    } else {
      this.crearFormulario(undefined)
      this.titulo = "Crear Evento"
      this.precio_editable=true
     // this.habilitarCampo()
    }
      } catch {
        this.crearFormulario(undefined)
        this.titulo = "Crear Evento "
        this.precio_editable=true

     //   this.habilitarCampo()

      }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {


  }

  habilitarCampo() {
    this.cod_evento_editable = true
    this.formGestion.controls.codigo.enable()
    this.nombre_editable = true
    this.formGestion.controls.nombre.enable()

    this.sitios_editable = true
    this.formGestion.controls.ubicacion.enable()

    this.categoria_eventos_editable = true
    this.formGestion.controls.categoria.enable()
    this.tipo_evento_editable = true
    this.formGestion.controls.tipo.enable()
   // this.fechaMinima1.setMonth(this.fechaMinima1.getMonth() - 12)
    this.fecha_hora_inicio_editable = true

   // this.fechaMinima2.setMonth(this.fechaMinima2.getMonth() - 11)
   let inicio=new Date()
   let final=new Date()
   this.fechaMinima1.setDate(inicio.getDate())
   this.fechaMinima1.setMinutes(inicio.getMinutes() + 60)
   this.fechaMinima2.setHours(final.getHours() + 1)

    this.fecha_hora_fin_editable = true

    this.descripcion_breve_editable = true

    this.descripcion_general_editable = true

    this.aforo_editable = true
    this.formGestion.controls.aforo.enable()
    this.protocolo_bioseguridad_editable = true

    this.url_videos_editable = true
    this.formGestion.controls.video.enable()
    this.url_encuesta_editable = true
    this.formGestion.controls.encuesta.enable()
    this.url_informacion_adicional_editable = true
    this.formGestion.controls.adicional.enable()
    this.url_informacion_adicional_editable = true
    this.formGestion.controls.adicional.enable()
    this.precio_editable = true
    this.formGestion.controls.costo.enable()
  }
  obtenerEvento() {
    this.ngxUiLoaderService.start()
    this.eventoService.obtenerEvento(this.eventoSeleccionado.id).subscribe(res => {
      if (res.success) {
        this.eventoSeleccionado = res.data
        this.crearFormulario(res.data)
        setTimeout(r => {

          this.formGestion.controls.aforo.setValue(this.ubicacionSeleccionada.capacidad)

        }, 500)

      } else {
        this.alertService.crearMensaje("error", res.message)
        this.ngxUiLoaderService.stop()

      }

    }, error => {
      this.ngxUiLoaderService.stop()
      this.alertService.crearMensaje("error", "Ocurrio un error")

    })
  }


  onFileSelected(event) {
    const max_size = 1000000;

    if (event.target.files[0].size > max_size) {
      this.alertService.crearMensaje("error", "Tamaño de imagen no permitida")
      return false;
    } else {
      //console.log("tamaño permitido", event.target.files[0].size)
    }
    let i = 0
    let me = this
    Array.prototype.forEach.call(event.target.files, function (file) {
      if (me.validFile.find(x => x === file.type) != null && me.validFile.find(x => x === file.type) != undefined) {
        i++

        const reader = new FileReader();
        reader.readAsDataURL(file);
        let me2 = this

        reader.onloadend = (e) => {

          let width
          let height
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            width = img.naturalHeight;
            height = img.naturalWidth;
          };

          const max_height = 525;
          const max_width = 800;
          setTimeout(r => {
            //console.log(height, width)
            if (height != max_width && width != max_height) {
              me.alertService.crearMensaje("error", "Dimensiones de imagen no permitida (800*525)")
              return false
            } else {
              //console.log("dimensiones permitidas", width + "X" + height)
            }

            me.archivos.push({ base64: reader.result, nombre: file.name })


          }, 300)
        }
      } else {
        me.alertService.crearMensaje("error", "Formato inválido: " + file.name)
      }
    });



  }






  obtenerCapacidad() {
    let ubi = this.ubicaciones.find(x => Number(x.id) === Number(this.formGestion.controls.ubicacion.value))

    if (ubi === undefined) {
      this.ubicacionSeleccionada = undefined

      this.formGestion.controls.aforo.setValue("")
      return ""
    } else {
      this.ubicacionSeleccionada = ubi
      if (Number(this.formGestion.controls.aforo.value) > Number(ubi.capacidad)) {
        this.formGestion.controls.aforo.setValue(ubi.capacidad)

      }

      return ubi.capacidad
    }
  }

  validarTamano() {
    try {

      if (Number(this.formGestion.controls.aforo.value) > Number(this.ubicacionSeleccionada.capacidad)) {
        this.alertService.crearMensaje("error", "El aforo debe ser menor a " + this.ubicacionSeleccionada.capacidad)
        this.formGestion.controls.aforo.setValue(this.ubicacionSeleccionada.capacidad)
      }

    } catch (e) {

    }
  }




  eliminarRecurso(i, archivo) {
    this.ngxUiLoaderService.start()

    if (this.eventoSeleccionado != undefined) {
      this.eventoService.eliminarImagen(this.eventoSeleccionado.id, archivo.id).subscribe(resp => {
        if (resp.success) {
          this.archivos.splice(i, 1)

          this.alertService.crearMensaje("success", resp.message)
        } else {
          this.alertService.crearMensaje("error", resp.message)

        }
        this.ngxUiLoaderService.stop()

      })
    } else {
      this.archivos.splice(i, 1)

    }

  }

  obtenerCategoriaEventos() {
    this.categoriaService.obtenerCategoriaEventosGestion().subscribe(resp => {
      if (resp.success) {
        this.categoriaEventos = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }

  obtenerUbicaciones() {
    this.lugarService.obtenerUbicacionEvento().subscribe(resp => {
      if (resp.success) {
        this.ubicaciones = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerTipoEventos() {
    this.tipoService.obtenerTipoEventosGestion().subscribe(resp => {
      if (resp.success) {
        this.tipoEventos = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    }, error => {
      this.ngxUiLoaderService.stop()
      this.alertService.crearMensaje("error", "Ocurrio un error")
        this.errorService.notificarError(error.message).subscribe(resp=>{})
      
    })
  }

  obtenerEstadoEventos() {
    this.estadoEventosService.obtenerEstadoEventos().subscribe(resp => {
      if (resp.success) {
        this.estadoEventos = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }



  crearFormulario(data) {
    let fecha1 = new Date(this.fechaMinima1)
    let fecha2 = new Date()
    fecha2.setDate(fecha2.getDate() + 1)

    try {

      if (!this.flagClonar) {
        this.formGestion = this._formBuilder.group({
          categoria: [data.categoria_eventos_entity.id, [Validators.required]],
          nombre: [data.nombre, [Validators.required]],
          codigo: [data.cod_evento, [Validators.required]],

          video: [data.url_videos, [Validators.pattern("https?://.*")]],
          ubicacion: [data.sitios_entity.id, [Validators.required]],
          aforo: [data.aforo, [Validators.required]],
          tipo: [data.tipo_evento, [Validators.required]],
          costo: [data.precio],
          encuesta: [data.url_encuesta, [Validators.pattern("https?://.*")]],
          adicional: [data.url_informacion_adicional, [Validators.pattern("https?://.*")]],
          estado: [],
          inicio: [new Date(data.fecha_hora_inicio)],
          fin: [new Date(data.fecha_hora_fin)],
        })
        this.costo = data.precio
        this.formGestion.controls.inicio.markAsTouched()
        this.formGestion.controls.fin.markAsTouched()
      } else {
        this.formGestion = this._formBuilder.group({
          categoria: [data.categoria_eventos_entity.id, [Validators.required]],
          nombre: [data.nombre, [Validators.required]],
          codigo: [data.cod_evento, [Validators.required]],

          video: [data.url_videos, [Validators.pattern("https?://.*")]],
          ubicacion: [data.sitios_entity.id, [Validators.required]],
          aforo: [data.aforo, [Validators.required]],
          tipo: [data.tipo_evento, [Validators.required]],
          costo: [data.precio],
          encuesta: [data.url_encuesta, [Validators.pattern("https?://.*")]],
          adicional: [data.url_informacion_adicional, [Validators.pattern("https?://.*")]],
          estado: [],
          inicio: [fecha1],
          fin: [fecha2],
        })
        this.costo = data.precio
      }

      this.ngxUiLoaderService.start()

      setTimeout(r => {
        this.ngxUiLoaderService.start()

        this.generalEditor.editor.setContent(this.eventoSeleccionado.descripcion_general)
        this.breveEditor.editor.setContent(this.eventoSeleccionado.descripcion_breve)
        this.protocoloEditor.editor.setContent(this.eventoSeleccionado.protocolo_bioseguridad)
        this.ngxUiLoaderService.stop()

      }, 5000)



    } catch {


      this.formGestion = this._formBuilder.group({
        categoria: ["", [Validators.required]],
        nombre: ["", [Validators.required]],
        codigo: ["", [Validators.required]],
        video: ["", [Validators.pattern("https?://.*")]],
        ubicacion: ["", [Validators.required]],
        aforo: [1, [Validators.required]],
        tipo: ["", [Validators.required]],
        costo: [""],
        encuesta: ["", [Validators.pattern("https?://.*")]],
        adicional: ["", [Validators.pattern("https?://.*")]],
        estado: [],
        inicio: [fecha1],
        fin: [fecha2],
      }
      );
      this.costo = ""
      this.archivos = []
    }


  }

  navegarEvento() {
    if (this.eventoSeleccionado != undefined) {
      history.back()
    } else {
      this.navegacionService.navegarEventos()
    }
  }


  editarEvento() {
    this.ngxUiLoaderService.start()
    if (this.breveEditor.editor.getContent() === undefined || this.breveEditor.editor.getContent() === null || this.breveEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar la descripción breve")
      return
    }
    if (this.generalEditor.editor.getContent() === undefined || this.generalEditor.editor.getContent() === null || this.generalEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar la descripción general ")
      return
    }
    if (this.protocoloEditor.editor.getContent() === undefined || this.protocoloEditor.editor.getContent() === null || this.protocoloEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar un protocolo")
      return
    }


    let aux_inicio = new Date(this.formGestion.controls.inicio.value)
    let fecha_inicio = aux_inicio.getFullYear() + "-" + (aux_inicio.getMonth() + 1) + "-" + aux_inicio.getDate() + " " + aux_inicio.getHours() + ":" + aux_inicio.getMinutes() + ":" + aux_inicio.getSeconds()


    let aux_fin = new Date(this.formGestion.controls.fin.value)
    let fecha_fin = aux_fin.getFullYear() + "-" + (aux_fin.getMonth() + 1) + "-" + aux_fin.getDate() + " " + aux_fin.getHours() + ":" + aux_fin.getMinutes() + ":" + aux_fin.getSeconds()


    this.costo
    this.formGestion.controls.costo.setValue(this.costo)

    //console.log(new Date(this.formGestion.controls.inicio.value), this.formGestion.controls.inicio.value)
    this.eventoService.editarEventos(this.eventoSeleccionado.id, this.formGestion.controls.codigo.value, this.formGestion.controls.nombre.value, this.breveEditor.editor.getContent(), this.generalEditor.editor.getContent(), this.formGestion.controls.adicional.value, this.formGestion.controls.video.value, this.formGestion.controls.encuesta.value, this.formGestion.controls.aforo.value, this.formGestion.controls.costo.value, fecha_inicio, fecha_fin, this.formGestion.controls.ubicacion.value, this.formGestion.controls.categoria.value, this.formGestion.controls.tipo.value, this.protocoloEditor.editor.getContent()).subscribe(resp => {

      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        /*this.archivos.forEach(archivo => {
          if (archivo.id === undefined || archivo.id === null) {
            this.cargarImagen(this.eventoSeleccionado, archivo)
          }
        })*/
        this.eventoService.obtenerEvento(this.eventoSeleccionado.id).subscribe(resp => {
          if (resp.success) {
            this.eventoSeleccionado = resp.data
            this.navegarEvento()
          }
        })
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  crearEvento() {
    if (this.archivos.length === 0) {
      this.alertService.crearMensaje("error", "Se debe añadir una imagen como minímo")
      return;

    }
    if (this.breveEditor.editor.getContent() === undefined || this.breveEditor.editor.getContent() === null || this.breveEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar la descripción breve")
      return
    }
    if (this.generalEditor.editor.getContent() === undefined || this.generalEditor.editor.getContent() === null || this.generalEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar la descripción general ")
      return
    }
    if (this.protocoloEditor.editor.getContent() === undefined || this.protocoloEditor.editor.getContent() === null || this.protocoloEditor.editor.getContent().trim() === "") {
      this.alertService.crearMensaje("error", "Se debe ingresar un protocolo")
      return
    }
    let aux_inicio = new Date(this.formGestion.controls.inicio.value)
    let fecha_inicio = aux_inicio.getFullYear() + "-" + (aux_inicio.getMonth() + 1) + "-" + aux_inicio.getDate() + " " + aux_inicio.getHours() + ":" + aux_inicio.getMinutes() + ":" + aux_inicio.getSeconds()


    let aux_fin = new Date(this.formGestion.controls.fin.value)
    let fecha_fin = aux_fin.getFullYear() + "-" + (aux_fin.getMonth() + 1) + "-" + aux_fin.getDate() + " " + aux_fin.getHours() + ":" + aux_fin.getMinutes() + ":" + aux_fin.getSeconds()

    this.ngxUiLoaderService.start()
    this.formGestion.controls.costo.setValue(this.costo)
    this.eventoService.crearEventos(this.formGestion.controls.codigo.value, this.formGestion.controls.nombre.value, this.breveEditor.editor.getContent(), this.generalEditor.editor.getContent(), this.formGestion.controls.adicional.value, this.formGestion.controls.video.value, this.formGestion.controls.encuesta.value, this.formGestion.controls.aforo.value, this.formGestion.controls.costo.value, fecha_inicio, fecha_fin, this.formGestion.controls.ubicacion.value, this.formGestion.controls.categoria.value, this.formGestion.controls.tipo.value, this.protocoloEditor.editor.getContent()).subscribe(resp => {

      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        if (this.archivos.length != 0) {
          this.eventoSeleccionado = resp.data

          this.archivos.forEach(img => {
            this.cargarImagen(resp.data, img)
          })


        } else {
          this.alertService.crearMensaje("error", "Se debe añadir una imagen como minímo")
          this.archivos = []

          //  this.eventoSeleccionado=resp.data

          // this.verDetalle(resp.data)
        }
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  navegarEventos() {
    this.navegacionService.navegarEventos()
  }
  cargarImagen(resp, img) {
    this.eventoService.cargarImagen(resp.id, img.nombre, img.base64).subscribe(resp2 => {
      if (resp2.success) {
        this.navegarEventos()

      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }



  verDetalle(data) {
    this.eventoService.obtenerEvento(data.id).subscribe(resp => {
      if (resp.success) {
        this.navegacionService.navegarOpcionesEventos(resp.data)
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  handleDOBChange(val) {
  }
  breveEmpty() {
    if (this.breveEditor != undefined && (
      this.breveEditor.editor.getContent().trim() === null || this.breveEditor.editor.getContent().trim() === undefined || this.breveEditor.editor.getContent().trim() === "")) {
      return true

    } else {
      return false
    }
  }
  generalEmpty() {
    if (this.generalEditor != undefined && (
      this.generalEditor.editor.getContent().trim() === null || this.generalEditor.editor.getContent().trim() === undefined || this.generalEditor.editor.getContent().trim() === "")) {
      return true

    } else {
      return false
    }
  }

  protocoloEmpty() {
    if (this.protocoloEditor != undefined && (
      this.protocoloEditor.editor.getContent().trim() === null || this.protocoloEditor.editor.getContent().trim() === undefined || this.protocoloEditor.editor.getContent().trim() === "")) {
      return true
    } else {
      return false
    }
  }

  calcularFecha1Minima() {
    let aux = new Date()
    if (this.fechaMinima1.getTime() < aux.getTime()) {
      return aux
    } else {
      return this.fechaMinima1

    }
  }

  cambioFechaIncio(event, info) {
    //console.log(event, info)

    let fecha_inicio = new Date(this.formGestion.controls.inicio.value)
    let fecha_fin = new Date(this.formGestion.controls.fin.value)

    if (fecha_inicio.getTime() > fecha_fin.getTime()) {

      this.fechaMinima2.setHours(fecha_inicio.getHours() + 1)
      this.formGestion.controls.fin.setValue(fecha_inicio.setDate(fecha_inicio.getDate() + 1))
      this.formGestion.controls.fin.markAsTouched()
    } else {

    }
  }

  controlnumero() {
    this.formGestion.controls.aforo.setValue(this.formGestion.controls.aforo.value.replace(/[^0-9.]*/g, ''))

  }


}

