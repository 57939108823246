import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthService } from '../../../services/auth/auth.service';
import { NavegacionService } from '../../../services/navegacion/navegacion.service';

@Component({
  selector: 'landing',
  templateUrl: 'landing.component.html',
  styleUrls: ['landing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Landing implements OnInit {
  dataUsuario
  title
  constructor(private router: Router, private navegacion: NavegacionService, private authService: AuthService, private alertService: AlertService) {
    this.validarToken()
    this.obtenerInformacionUsuario()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        //console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        //console.log(event);
        if (event.url === "/admin") {
          this.title = "INICIO"
        }
        if (event.url === "/admin/eventos") {
          this.title = "EVENTOS"
        }
        if (event.url === "/admin/gestion-evento") {
          this.title = "EVENTOS / GESTIÓN EVENTO"
        }
        if (event.url === "/admin/ver-detalle-evento") {
          this.title = "EVENTOS / DETALLE EVENTO"
        }
        if (event.url === "/admin/parametros/roles") {
          this.title = "PARAMETROS / ROLES"
        }
        if (event.url === "/admin/parametros/dependencias") {
          this.title = "PARAMETROS / DEPENDENCIAS"
        }
        if (event.url === "/admin/parametros/areas") {
          this.title = "PARAMETROS / AREAS"
        }
        if (event.url === "/admin/parametros/facultad") {
          this.title = "PARAMETROS / FACULTAD"
        }
        if (event.url === "/admin/parametros/programa") {
          this.title = "PARAMETROS / PROGRAMA"
        }
        if (event.url === "/admin/parametros/estado-participantes") {
          this.title = "PARAMETROS / ESTADO DE PARTICIPANTES"
        }
        if (event.url === "/admin/parametros/estado-evento") {
          this.title = "PARAMETROS / ESTADO DE EVENTO"
        }
        if (event.url === "/admin/usuarios") {
          this.title = "USUARIOS"
        }
        if (event.url === "/admin/metricas") {
          this.title = "METRICAS"
        }
        if (event.url === "/admin/log") {
          this.title = "LOGS"
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        //console.log(event.error);
      }
    });
  }
  ngOnInit() {

  }

  validarToken() {
    this.authService.validarToken().subscribe(resp => {
      if (resp.success) {
        // this.alertService.crearMensaje('success',resp.message)
      } else {
        this.alertService.crearMensaje('error', resp.message)
        this.authService.cerrarSesion()
      }
    })
  }
  obtenerInformacionUsuario() {
    this.authService.obtenerInformacionUsuario().subscribe(resp => {
      if (resp.success) {
        // this.alertService.crearMensaje('success',resp.message)
        this.dataUsuario = resp.data
      } else {
        this.alertService.crearMensaje('error', resp.message)
        this.authService.cerrarSesion()
      }
    })
  }
  navegarInicio() {
    this.navegacion.navegarInicio()
  }
  navegarEventos() {
    this.navegacion.navegarEventos()
  }
  navegarMetricas() {
    this.navegacion.navegarMetricas()
  }
  navegarUsuarios() {
    this.navegacion.navegarUsuarios()
  }
  navegarLog() {
    this.navegacion.navegarLog()
  }
  navegarRoles() {
    this.navegacion.navegarRoles()
  }
  navegarDependencia() {
    this.navegacion.navegarDependencias()
  }
  navegarAreas() {
    this.navegacion.navegarAreas()
  }
  navegarFacultad() {
    this.navegacion.navegarFacultad()
  }
  navegarPrograma() {
    this.navegacion.navegarPrograma()
  }
  navegarEstadoParticipante() {
    this.navegacion.navegarEstadoParticipante()
  }
  navegarEstadoEvento() {
    this.navegacion.navegarEstadoEvento()
  }
  navegarDocumentos() {
    this.navegacion.navegarDocumentos()
  }
  navegarLugares() {
    this.navegacion.navegarLugares()
  }
  navegarCategoriaEventos() {
    this.navegacion.navegarCategoriaEventos()
  }
  navegarTipoEventos() {
    this.navegacion.navegarTipoEventos()
  }

  cerrarSesion() {
    this.authService.cerrarSesion()
  }


}