import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../../properties';

@Injectable({
  providedIn: 'root'
})
export class EstadoEventosService {

  constructor(private http: HttpClient, private router: Router) { }
  obtenerEstadoEventos(): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    return this.http.get(Properties.URL + 'api/parametros/listado-estado-de-evento', { headers: headers })
  }

  obtenerEstadoDisponibleEventos(id_evento): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {
      id_evento: id_evento
    }
    return this.http.post(Properties.URL + 'api/eventos/obtener-estados-evento-disponible', raw, { headers: headers })
  }
  cambiarEstadoEvento(id_evento, estado): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)
      });
    let raw = {
      "id_evento": id_evento,
      "estado": estado
    }
    return this.http.post(Properties.URL + 'api/eventos/cambiar-estado-evento', raw, { headers: headers })
  }




}
