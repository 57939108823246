import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid
} from "ng-apexcharts";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from '../../services/alert/alert.service';
import { ErrorService } from '../../services/error/error.service';
import { MetricaService } from '../../services/metrica/metrica.service';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  @ViewChild('chartline', { static: false }) private chart_line: ElementRef;
  @ViewChild('chartline2', { static: false }) private chart_line2: ElementRef;

  pieChart: any;
  pieChart2:any
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptions2: Partial<ChartOptions>;

  grafica1_cantidad_x_estados = []
  grafica1_cantidad_x_estados_label = []
  grafica2_cantidad_x_categoria = []
  grafica2_cantidad_x_categoria_label = []
    smallScreen
  
  color = ["#B2B200", "#A3D900", "#00B22D", "#008C8C", "#3cba9f", "#0059B2"]
  constructor(private metricasService: MetricaService,private errorService:ErrorService, private ngxUiLoaderService: NgxUiLoaderService, private alertService: AlertService,    private breakpointObserver: BreakpointObserver
    ) {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        //console.log(result.matches, this.smallScreen)
        if(result.matches!=this.smallScreen && this.smallScreen!=undefined){
          this.graficar()

        }
        this.smallScreen = result.matches;
      });
      this.obtenerMetricas()

  }

  ngOnInit(): void {
  }

  obtenerMetricas() {
    this.ngxUiLoaderService.start()

    this.metricasService.obtenerMetricas().subscribe(resp => {
      if (resp.success) {
        this.grafica1_cantidad_x_estados = []
        this.grafica1_cantidad_x_estados_label = []
        this.grafica2_cantidad_x_categoria = []
        this.grafica2_cantidad_x_categoria_label = []
        let i = 0
        resp.data.grafica1_cantidad_x_estados.forEach(element => {

          let aux = { data: [], label: "", fill: false, borderColor: "", backgroundColor: "" }
          aux.data = [element.cantidad]
          aux.label = element.estado
          aux.borderColor = this.color[i]
          aux.backgroundColor = this.color[i]
          if (i >= this.color.length - 1) {
            i = 0
          } else {
            i++

          }
          this.grafica1_cantidad_x_estados.push(aux)
          this.grafica1_cantidad_x_estados_label.push(element.estado)

        });
        let y = 0

        resp.data.grafica2_cantidad_x_categoria.forEach(element => {
          let aux = { data: [], label: "", fill: false, borderColor: "", backgroundColor: "" }
          aux.data = [element.cantidad]
          aux.label = element.categoria
          aux.borderColor = this.color[y]
          aux.backgroundColor = this.color[y]
          if (y >= this.color.length - 1) {
            y = 0
          } else {
            y++

          }
          this.grafica2_cantidad_x_categoria.push(aux)
          this.grafica2_cantidad_x_categoria_label.push(element.estado)

        });

        this.graficar()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  graficar(){
    setTimeout(r=>{
      //console.log(this.grafica1_cantidad_x_estados)
      this.pieChart = new Chart(this.chart_line.nativeElement, {
        type: 'bar',
        data: {
          labels: [],
          datasets: this.grafica1_cantidad_x_estados,
        },
  
        options: {
          maintainAspectRatio: false,

          title: {
            display: true,
            text: 'Gráfica Total Participantes en Eventos por Estado:'
          },
          hover: {
            mode: 'index',
            axis: 'y'
          },
          
          tooltips: {
            callbacks: {
          
          title:function(tooltipItem, data) { 
            return ""
          }
      }
         },
  
  
          legend: {}
        }
      });
  
      //console.log()
      this.pieChart2 = new Chart(this.chart_line2.nativeElement, {
        type: 'bar',
        data: {
          labels: [],
          datasets: this.grafica2_cantidad_x_categoria,
        },
  
        options: {
          maintainAspectRatio: false,

          title: {
            display: true,
            text: 'Gráfica Total Participantes en Eventos por Categoría:'
          },
          hover: {
            mode: 'index',
            axis: 'y'
          },
          tooltips: {
            callbacks: {
          
          title:function(tooltipItem, data) { 
            return ""
          }
      }
         },
  
  
          legend: {}
        }
      });
      this.ngxUiLoaderService.stop()

    },1000)
    
  }
 

  ngAfterViewInit() {
   
  }
}
