import { Component, OnInit } from '@angular/core';
import * as moment from 'moment'
import { speedDialFabAnimations } from '../../@pages/animations/speed-dial-fab.animations';
import { EventosService } from '../../services/eventos/eventos.service';
import { MetricaService } from '../../services/metrica/metrica.service';
import * as xlsx from "xlsx";

@Component({
  selector: 'app-metricas',
  templateUrl: './metricas.component.html',
  styleUrls: ['./metricas.component.scss'],
  animations: speedDialFabAnimations,

})
export class MetricasComponent implements OnInit {
  desde: Date;
  
  hasta;
  fabTogglerState = 'inactive';
  buttons = [];
  fabButtons = [{ icon: 'timeline' }];
  constructor(private eventoService:EventosService) { 
    this.desde = new Date();

    this.hasta = new Date();
    this.desde.setDate( this.desde.getDate()-1)
  }

  ngOnInit() {
  }


  exportToExcelService(criterio) {
    const format1 = "YYYY-MM-DD"

    this.eventoService.exportExcel(moment(this.desde).format(format1), moment(this.hasta).format(format1),criterio).subscribe(res => {
      if (res.success) {
        let newArray: any[] = [];

        let data = Object.values(res.data.eventos_x_dependencia);
        Object.keys(data).forEach((key, index) => {
          //console.log(data[key])

        
          newArray.push(data[key]);
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        let date = new Date();

        xlsx.writeFile(
          wb,

          "COLMAYOR_APP_MIBIENESTAR_"+criterio+"_" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear() + ".xlsx"
        );
      } else {

      }
    })
  }

  
  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }
  onToggleFab() {

    this.buttons.length ? this.hideItems() : this.showItems();
  }
  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  obtenerMetricas(){

  }

  obtenerStringEvento() {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    let fecha1 = new Date(this.desde)
    let fecha2 = new Date(this.hasta)

    let hora1:any=fecha1.getHours()
    let minuto1:any=fecha1.getMinutes()
    
    let hora2:any=fecha2.getHours()
    let minuto2:any=fecha2.getMinutes()
    if(hora1<10){
      hora1="0"+hora1
    }
    if(minuto1<10){
      minuto1="0"+minuto1
    }
    if(hora2<10){
      hora2="0"+hora2
    }
    if(minuto2<10){
      minuto2="0"+minuto2
    }

    return ("DEL " + fecha1.toLocaleDateString("es-ES", options)+ " AL " + fecha2.toLocaleDateString("es-ES", options)).toUpperCase()

  }
}
