import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as xlsx from "xlsx";
import { AlertService } from '../../services/alert/alert.service';
import { ErrorService } from '../../services/error/error.service';
import { UsuariosService } from '../../services/usuarios/usuarios.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {
  displayedColumns: string[] = ['dni', 'nombre', 'apellido', 'perfil', 'area', 'email', 'estado', 'Opciones'];
  dataSource
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private usuariosService: UsuariosService,private errorService:ErrorService,  private ngxUiLoaderService: NgxUiLoaderService, private alertService: AlertService,) {
    this.obtenerUsuarios()
  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  obtenerUsuarios() {
      this.ngxUiLoaderService.start()
    this.usuariosService.obtenerUsuarios().subscribe(resp => {

      if (resp.success) {
        this.dataSource = new MatTableDataSource(resp.data);
        this.configurarTabla()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  configurarTabla() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  asociarUsuario(dni) {
    this.usuariosService.asociarUsuario(dni).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerUsuarios()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  desasociarUsuario(dni) {
    this.usuariosService.desasociarUsuario(dni).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerUsuarios()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  acitvarInactivarUsuario(dni) {
    this.usuariosService.activarInactivar(dni).subscribe(resp => {
      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.obtenerUsuarios()
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Número de documento": data[key].dni,
        "Nombres": data[key].primer_nombre,
        "Apellidos": data[key].primer_apellido,
        "Perfil o rol": data[key].rol,
        "Área/Programas": data[key].area,
        "Email personal": data[key].email,
        "Activo": data[key].estado,
      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_USUARIOS_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }

}