import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploader } from 'ng2-file-upload';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { esLocale } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import * as xlsx from "xlsx";
import { EventosService } from '../../services/eventos/eventos.service';
import { AlertService } from '../../services/alert/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosService } from '../../services/parametros/parametros.service';
import { LugaresService } from '../../services/parametros/lugares/lugares.service';
import { CategoriaEventosService } from '../../services/parametros/categoria-eventos/categoria-eventos.service';
import { TipoEventosService } from '../../services/parametros/tipo-eventos/tipo-eventos.service';
import { EstadoEventosService } from '../../services/parametros/estado-eventos/estado-eventos.service';
import { speedDialFabAnimations } from '../../@pages/animations/speed-dial-fab.animations';
import * as moment from 'moment'
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ErrorService } from '../../services/error/error.service';


@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
  animations: speedDialFabAnimations,

})
export class EventosComponent implements OnInit {
  displayedColumns: string[] = ['codigo', 'categoria', 'nombre', 'fecha_hora_inicio', 'fecha_hora_fin', 'ubicacion', 'aforo', 'nro_inscritos', 'tasa_participacion', 'tipo_evento', 'estado', 'opciones'];
  dataSource: MatTableDataSource<any[]>;
  modalRef?: BsModalRef;
  fabTogglerState = 'inactive';
  buttons = [];
  fabButtons = [{ icon: 'timeline' }];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  locale = 'es';
  locales = listLocales();
  categoriaEventos = []
  ubicaciones = []
  estadoEventos = []
  tipoEventos = []
  archivos = []
  archivosAux = []
  formGestion: FormGroup
  eventoSeleccionado

  desde: Date;
  hasta;
  maxDate = new Date();

  categoria = ""

  estado = ""

  guardarFiltro = false
  constructor(private navegacion: NavegacionService,private errorService:ErrorService,  private ngxUiLoaderService: NgxUiLoaderService, private localeService: BsLocaleService, private modalService: BsModalService, private eventoService: EventosService, private alertService: AlertService, private _formBuilder: FormBuilder, private parametricosService: ParametrosService, private lugarService: LugaresService, private categoriaService: CategoriaEventosService, private tipoService: TipoEventosService, private estadoEventosService: EstadoEventosService) {
    // Create 100 users

    // Assign the data to the data source for the table to render

    try {
      if (localStorage.getItem("recordarFiltroEvento") === null) {
        this.guardarFiltro = false
        this.desde = new Date("2021-01-11");
        this.hasta = new Date();
      } else {
        this.guardarFiltro = true
        let filtro = JSON.parse(this.obtenerFiltroStorage())
        this.desde = new Date(filtro.desde);
        this.hasta = new Date(filtro.hasta);
        this.categoria = filtro.categoria;
        this.estado = filtro.estado;

      }
    } catch {
      this.guardarFiltro = false
      this.desde = new Date("2021-01-11");
      this.hasta = new Date();
    }
    this.obtenerCategoriaEventos()
    this.obtenerEstadoEventos()
    defineLocale("es", esLocale)
    this.obtenerEventos(undefined)


  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  verDetalle(registro) {
    this.eventoService.obtenerEvento(registro.id).subscribe(resp => {
      if (resp.success) {
        this.navegacion.navegarOpcionesEventos(resp.data)
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  gestionarEvento(template: TemplateRef<any>, c, data) {
    this.archivos = []
    this.obtenerCategoriaEventos()
    this.obtenerTipoEventos()
    this.obtenerUbicaciones()
    this.obtenerEstadoEventos()
    this.openModal(template, c)
    if (data != undefined) {
      this.eventoSeleccionado = data
      this.archivos = data.recursos
      this.archivosAux = this.archivos
    } else {
      this.eventoSeleccionado = undefined
    }
    this.crearFormulario(data);

  }

  onFileSelected(event) {
    let i = 0
    let me = this
    Array.prototype.forEach.call(event.target.files, function (file) {
      i++
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let me2 = this
      reader.onloadend = (e) => {
        me.archivos.push({ base64: reader.result, nombre: file.name })
      };
    });
  }

  eliminarRecurso(i, archivo) {
    if (this.eventoSeleccionado != undefined) {
      this.eventoService.eliminarImagen(this.eventoSeleccionado.id, archivo.id).subscribe(resp => {
        if (resp.success) {
          this.archivos.splice(i, 1)

          this.alertService.crearMensaje("success", resp.success)
        } else {
          this.alertService.crearMensaje("error", resp.success)

        }
      })
    } else {
      this.archivos.splice(i, 1)

    }

  }


  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }

  exportToExcel() {
    let newArray: any[] = [];

    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Código del evento": data[key].cod_evento,
        "Categoria": data[key].categoria,
        "Nombre del evento": data[key].nombre,
        "Fecha/Hora inicio": data[key].fecha_hora_inicio,
        "Fecha/Hora fin": data[key].fecha_hora_fin,
        "Ubicación": data[key].nombre_aula,
        "Aforo": data[key].aforo,
        "nro_inscritos": data[key].nro_inscritos,
        "tasa_participacion": data[key].tasa_participacion,
        "Tipo de evento": data[key].tipo,
        "Estado": data[key].estado,

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,

      "COLMAYOR_APP_MIBIENESTAR_EVENTOS_" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear() + ".xlsx"
    );
  }

  exportToExcelService(criterio) {
    const format1 = "YYYY-MM-DD"

    this.eventoService.exportExcel(moment(this.desde).format(format1), moment(this.hasta).format(format1),criterio).subscribe(res => {
      if (res.success) {
        let newArray: any[] = [];

        let data = Object.values(res.data.eventos_x_dependencia);
        Object.keys(data).forEach((key, index) => {
     

        
          newArray.push(data[key]);
        });

        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        let date = new Date();

        xlsx.writeFile(
          wb,

          "COLMAYOR_APP_MIBIENESTAR_EVENTOS_DETALLADO" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear() + ".xlsx"
        );
      } else {

      }
    })
  }

  obtenerEventos(accion) {
    //console.log(this.desde, this.hasta)
    const format1 = "YYYY-MM-DD"
    this.ngxUiLoaderService.start()
    this.eventoService.obtenerEventos(this.categoria, moment(this.desde).format(format1), moment(this.hasta).format(format1), this.estado).subscribe((resp: any) => {
      if (resp.success) {
        this.dataSource = new MatTableDataSource(resp.data);

        if (accion === "editar") {
          this.eventoSeleccionado = resp.data.find(x => x.id === this.eventoSeleccionado.id)
          this.crearFormulario(this.eventoSeleccionado)
          //console.log(this.eventoSeleccionado)
        }
        this.configurarTabla()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
      this.ngxUiLoaderService.stop()

    }, error => {
      this.alertService.crearMensaje("error", "Ocurrio un error")
      this.errorService.notificarError(error.message).subscribe(resp=>{})

    })
  }

  obtenerCategoriaEventos() {
    this.categoriaService.obtenerCategoriaEventosGestion().subscribe(resp => {
      if (resp.success) {
        this.categoriaEventos = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerUbicaciones() {
    this.lugarService.obtenerUbicacionEvento().subscribe(resp => {
      if (resp.success) {
        this.ubicaciones = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerTipoEventos() {
    this.tipoService.obtenerTipoEventosGestion().subscribe(resp => {
      if (resp.success) {
        this.tipoEventos = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    })
  }

  obtenerEstadoEventos() {
    this.estadoEventosService.obtenerEstadoEventos().subscribe(resp => {
      if (resp.success) {
        this.estadoEventos = resp.data

        if (this.guardarFiltro) {
          this.guardarFiltroStorage({ desde: this.desde, hasta: this.hasta, categoria: this.categoria, estado: this.estado })
        }
        //console.log(resp.data)
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  editarEvento() {
    //console.log(this.formGestion)
    this.eventoService.editarEventos(this.eventoSeleccionado.id, this.formGestion.controls.codigo.value.toUpperCase(), this.formGestion.controls.nombre.value.toUpperCase(), this.formGestion.controls.breve.value, this.formGestion.controls.general.value, this.formGestion.controls.adicional.value, this.formGestion.controls.video.value, this.formGestion.controls.encuesta.value, this.formGestion.controls.aforo.value, this.formGestion.controls.costo.value, this.formGestion.controls.inicio.value, this.formGestion.controls.fin.value, this.formGestion.controls.ubicacion.value, this.formGestion.controls.categoria.value, this.formGestion.controls.tipo.value, this.formGestion.controls.bioseguridad.value).subscribe(resp => {

      if (resp.success) {
        this.modalRef.hide()
        this.alertService.crearMensaje("success", resp.message)
        this.archivos.forEach(archivo => {
          if (archivo.id === undefined || archivo.id === null) {
            this.cargarImagen(this.eventoSeleccionado, archivo)
          }
        })
        this.obtenerEventos("editar")
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
  crearEvento() {
    //console.log(this.formGestion)
    this.eventoService.crearEventos(this.formGestion.controls.codigo.value.toUpperCase(), this.formGestion.controls.nombre.value.toUpperCase(), this.formGestion.controls.breve.value, this.formGestion.controls.general.value, this.formGestion.controls.adicional.value, this.formGestion.controls.video.value, this.formGestion.controls.encuesta.value, this.formGestion.controls.aforo.value, this.formGestion.controls.costo.value, this.formGestion.controls.inicio.value, this.formGestion.controls.fin.value, this.formGestion.controls.ubicacion.value, this.formGestion.controls.categoria.value, this.formGestion.controls.tipo.value, this.formGestion.controls.bioseguridad.value).subscribe(resp => {

      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        if (this.archivos.length != 0) {

          this.archivos.forEach(img => {
            this.cargarImagen(resp.data, img)
          })
        } else {
          this.obtenerEventos(undefined)

        }
        this.modalRef.hide()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  cargarImagen(resp, img) {
    this.eventoService.cargarImagen(resp.id, img.nombre, img.base64).subscribe(resp2 => {
      if (resp2.success) {
        this.obtenerEventos(undefined)
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  crearFormulario(data) {
    try {
      this.formGestion = this._formBuilder.group({
        categoria: [data.categoria_eventos_entity.id, [Validators.required]],
        nombre: [data.nombre, [Validators.required]],
        codigo: [data.cod_evento, [Validators.required]],
        breve: [data.descripcion_breve, [Validators.required]],
        general: [data.descripcion_general, [Validators.required]],
        bioseguridad: [data.protocolo_bioseguridad, [Validators.required]],
        video: [data.url_videos, [Validators.pattern("https?://.*")]],
        ubicacion: [data.sitios_entity.id, [Validators.required]],
        aforo: [data.aforo, [Validators.required]],
        tipo: [data.tipo_evento, [Validators.required]],
        costo: [data.precio],
        encuesta: [data.url_encuesta, [Validators.pattern("https?://.*")]],
        adicional: [data.url_informacion_adicional, [Validators.pattern("https?://.*")]],
        estado: [],
        inicio: [data.fecha_hora_inicio],
        fin: [data.fecha_hora_fin],
      })

    } catch {
      this.formGestion = this._formBuilder.group({
        categoria: ["", [Validators.required]],
        nombre: ["", [Validators.required]],
        codigo: ["", [Validators.required]],
        breve: ["", [Validators.required]],
        general: ["", [Validators.required]],
        bioseguridad: ["", [Validators.required]],
        video: ["", [Validators.pattern("https?://.*")]],
        ubicacion: ["", [Validators.required]],
        aforo: ["", [Validators.required]],
        tipo: ["", [Validators.required]],
        costo: [""],
        encuesta: ["", [Validators.pattern("https?://.*")]],
        adicional: ["", [Validators.pattern("https?://.*")]],
        estado: [],
        inicio: [new Date()],
        fin: [new Date()],

      }
      );
      this.archivos = []
    }


  }
  obtenerCapacidad() {
    //console.log(this.formGestion.controls.ubicacion.value)
    let ubi = this.ubicaciones.find(x => Number(x.id) === Number(this.formGestion.controls.ubicacion.value))

    if (ubi === undefined) {
      //console.log("length", 10)
      return 10;
    } else {
      //console.log("length", ubi.capacidad)
      return ubi.capacidad
    }
  }


  configurarTabla() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.localeService.use(this.locale);
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }
  onToggleFab() {

    this.buttons.length ? this.hideItems() : this.showItems();
  }
  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  guardarFiltroStorage(filtro) {
    localStorage.setItem("filtroEvento", JSON.stringify(filtro))
  }
  obtenerFiltroStorage() {
    return localStorage.getItem("filtroEvento")
  }

  clonar(evento){
    this.eventoService.obtenerEvento(evento.id).subscribe(resp => {
      if (resp.success) {
        //console.log()
        this.navegacion.navegarGestionEvento(resp.data,"clonar")
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })


  }


  navegarGestion() {
    this.navegacion.navegarGestionEvento(undefined,undefined)
  }

  
}