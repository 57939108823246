import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { BsLocaleService, BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as xlsx from "xlsx";
import { AlertService } from '../../../services/alert/alert.service';
import { DocumentosLegalesService } from '../../../services/documentos-legales/documentos-legales.service';

@Component({
  selector: 'app-documentos-legales',
  templateUrl: './documentos-legales.component.html',
  styleUrls: ['./documentos-legales.component.scss']
})
export class DocumentosLegalesComponent implements OnInit {
  displayedColumns: string[] = ['nombre_documento','version','created_at','url_documento','estado'];
  dataSource 
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  modalRef?: BsModalRef;
  formGestion:FormGroup
  archivos=[]
  validFile = ["application/pdf"]
  version=""
  errorVersion=true
  constructor(private localeService: BsLocaleService,private _formBuilder: FormBuilder,private modalService: BsModalService,private documentoLegalService: DocumentosLegalesService,private alertService:AlertService) { 
    this.obtenerDocumentos()
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
  
  }

  
  
  obtenerDocumentos(){
    
    this.documentoLegalService.obtenerDocumentos().subscribe(resp=>{
      if(resp.success){
        this.dataSource = new MatTableDataSource(resp.data);
        this.configurarTabla()
      }else{
          this.alertService.crearMensaje("error",resp.message)
      }
    })
  }

  configurarTabla(){

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del '+ Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length+ ' registros';
    };
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  
  exportToExcel() {
    let newArray: any[] = [];
    let data = Object.values(this.dataSource.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Nombre del documento": data[key].nombre_documento,
        "Fecha y Hora de Carga": data[key].created_at,
        "Versión": data[key].version,

        "Link": data[key].url_documento,
        "Estado": data[key].estado,

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,
      
        "COLMAYOR_APP_MIBIENESTAR_DOCUMENTOS-LEGALES_"+date.getDate()+"_"+(date.getMonth()+1)+"_"+date.getFullYear()+".xlsx"
    );
  }
  
  openModal(template: TemplateRef<any>, c) {
    this.modalRef = this.modalService.show(
      template, {
      class: c, backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal(){
    this.modalRef.hide();
  }
  crearFormulario() {
 
      this.formGestion = this._formBuilder.group({
        nombre: ["", []],
        documento: ["", [Validators.required]],
        version: ["", [Validators.required]],
        descripcion: [""],
        tipo:["", [Validators.required]]
      }
      );

      this.version=this.version
      this.archivos = []
    
  }
  gestionarDocumento(template: TemplateRef<any>, c, data) {
    this.archivos=[]
    
    this.openModal(template, c)
    if (data != undefined) {
      this.archivos=data.recursos
    } else {
    }
    this.crearFormulario();
  }
  crearDocumento(){
    this.documentoLegalService.crearDocumento(this.formGestion.controls.tipo.value.toUpperCase(),this.formGestion.controls.descripcion.value,this.formGestion.controls.version.value,this.archivos[0].base64,this.formGestion.controls.tipo.value).subscribe(resp=>{
      if(resp.success){
        this.alertService.crearMensaje("success",resp.message)
        this.obtenerDocumentos()
        this.modalRef.hide()
      }else{
          this.alertService.crearMensaje("error",resp.message)
      }
    })
  }

  onFileSelected(event) {
    let i = 0
    let me = this
    Array.prototype.forEach.call(event.target.files, function (file) {
      if (me.validFile.find(x => x === file.type) != null && me.validFile.find(x => x === file.type) != undefined) {

      i++
      //console.log(file)
      const reader = new FileReader();
      reader.readAsDataURL(file);
      let me2 = this
      reader.onloadend = (e) => {
        me.archivos.push({ base64: reader.result, nombre: file.name })
      };
    }else{
      me.alertService.crearMensaje("error","Formato de documento inválido")
      me.formGestion.controls.documento.setValue("")
    }
    });
  }
  navegarA(url){
    window.open(url,"_blank")
  }

  imprimir(){
    this.formGestion.controls.version.setValue(this.formGestion.controls.version.value.replace(/[^0-9.]*/g, ''))
    //console.log(this.formGestion.controls.version.errors)
  }


}

