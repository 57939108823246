import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from '../../properties';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {


  constructor(private http: HttpClient) { }

  
  notificarError(error): Observable<any> {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
      });

let raw={
    "url":"wjwjwjw",  //RUTA DEL SERVICIO
    "method":"post", //METODO DE INVOCACION
    "body":"{jssjsjsjswjsjs}}", //BODY ENVIADO
    "response":"jhwwwhhwwhwjwwhw" //RESPONSE OBTENIDO COMO ERROR
}
    return this.http.post(Properties.URL + 'api/excepciones/reportar-bug',{error}, { headers: headers })

  }}
