import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { MatTableDataSource } from '@angular/material';

import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AlertService } from '../../services/alert/alert.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { NavegacionService } from '../../services/navegacion/navegacion.service';
import { EstadoEventosService } from '../../services/parametros/estado-eventos/estado-eventos.service';
import * as xlsx from "xlsx";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorService } from '../../services/error/error.service';

@Component({
  selector: 'app-eventos-opciones',
  templateUrl: './eventos-opciones.component.html',
  styleUrls: ['./eventos-opciones.component.scss']
})
export class EventosOpcionesComponent implements OnInit {
  displayedColumns: string[] = ['id', 'dni', 'nombre', 'apellido', "opciones"];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  data
  modalRef?: BsModalRef;
  cargandoImagen = false
  estados = []

  participantes: MatTableDataSource<any>;

  validFile = ["image/png", "image/jpeg", "image/jpg"]
  descripcion = "breve"
  evento = "participantes"

  estadoSeleccionado

  estadoEventosFull = []

  mensaje

  form:FormGroup
  participanteSeleccionado
  constructor(private router: Router,private errorService:ErrorService, private _formBuilder: FormBuilder ,private modalService: BsModalService, private estadoEventosService: EstadoEventosService, private navigateService: NavegacionService, private eventoService: EventosService, private alertService: AlertService, public navegarService: NavegacionService) {
    this.gotoTop()
    this.data = this.router.getCurrentNavigation().extras.state
    if (this.data === undefined) {
      -    this.navigateService.navegarEventos()

    }
    try {
      this.obtenerEstadoEventos()
      this.obtenerEstadoEventosFull()
      this.verDetalle()

      if (this.data === undefined) {
        this.navigateService.navegarEventos()
      } else {
        //console.log(this.data)
      }
    } catch {
      this.navigateService.navegarEventos()
    }
  }

  ngOnInit() {
  }

  verVideo() {
    window.open(this.data.url_videos, "_blank")
  }

  abrirUrl(url) {
    window.open(url, "_blank")
  }
  eliminarRecurso(i) {
    this.eventoService.eliminarImagen(this.data.id, this.data.recursos[i].id).subscribe(resp => {
      if (resp.success) {
        this.data.recursos.splice(i, 1)

        this.alertService.crearMensaje("success", resp.message)
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  navegarActualizarEvento() {
    //console.log(this.data)
    this.navegarService.navegarGestionEvento(this.data,undefined)
  }




  onFileSelected(event) {
    const max_size = 1000000;

    if (event.target.files[0].size > max_size) {
      this.alertService.crearMensaje("error", "Tamaño de imagen no permitida")
      return false;
    } else {
      //console.log("tamaño permitido", event.target.files[0].size)
    }
    let i = 0
    let me = this
    Array.prototype.forEach.call(event.target.files, function (file) {
      if (me.validFile.find(x => x === file.type) != null && me.validFile.find(x => x === file.type) != undefined) {
        i++

        const reader = new FileReader();
        reader.readAsDataURL(file);
        let me2 = this

        reader.onloadend = (e) => {
          me.cargandoImagen = true

          let width
          let height
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            width = img.naturalHeight;
            height = img.naturalWidth;
          };

          const max_height = 525;
          const max_width = 800;
          setTimeout(r => {
            if (height != max_width && width != max_height) {
              me.alertService.crearMensaje("error", "Dimensiones de imagen no permitida (800*525)")
              return false
            } else {
              //console.log("dimensiones permitidas", width + "X" + height)
            }

            me.eventoService.cargarImagen(me.data.id, file.name, reader.result).subscribe(resp => {
              if (resp.success) {
                me.cargandoImagen = false

                me.alertService.crearMensaje("success", resp.message)
                me.obtenerEvento()
              } else {
                me.cargandoImagen = false

                me.alertService.crearMensaje("error", resp.message)

              }

            }, error => {
              me.cargandoImagen = false

                this.errorService.notificarError(error.message).subscribe(resp=>{})
            })

          }, 300)
        }
      } else {
        me.alertService.crearMensaje("error", "Formato inválido: " + file.name)
      }
    });



  }

  obtenerEvento() {
    this.eventoService.obtenerEvento(this.data.id).subscribe(resp => {
      if (resp.success) {
        this.data = resp.data
        if (resp.data.recursos.length >= 3) {
          this.modalRef.hide()
        }
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  obtenerEstadoEventos() {
    this.estadoEventosService.obtenerEstadoDisponibleEventos(this.data.id).subscribe(resp => {
      if (resp.success) {
        this.estados = resp.data

        //console.log(resp.data)
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.alertService.crearMensaje("error", "Ocurrio un error ")
        this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  gestionarNotificacion(modal,participante){
    this.mensaje=""
    this.form = this._formBuilder.group({
      mensaje: ['', [Validators.required]],
    }
    );

    if(participante!=undefined){
      this.participanteSeleccionado=participante

    }
    this.openModal(modal)
   
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template, {
      class: "modal-lg", backdrop: true,
      ignoreBackdropClick: true
    }
    );
  }
  closeModal() {
    this.modalRef.hide();
  }
  navegarEvento() {
    this.navegarService.navegarEventos()

  }

  cambiarEstado(estado) {
    this.estadoSeleccionado = estado

  }

  cambiarEstadoServie() {
    this.estadoEventosService.cambiarEstadoEvento(this.data.id, this.estadoSeleccionado).subscribe(resp => {
      if (resp.success) {
        this.verDetalle()
        this.obtenerEstadoEventos()
        this.alertService.crearMensaje("success", resp.message)
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  verDetalle() {
    this.eventoService.obtenerEvento(this.data.id).subscribe(resp => {
      if (resp.success) {
        this.data = resp.data
        this.participantes = new MatTableDataSource(resp.data.asistentes);
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }

    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }

  configurarTabla() {
    this.participantes.paginator = this.paginator;
    this.participantes.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Elementos por página';
    this.paginator._intl.nextPageLabel = 'Página siguiente';
    this.paginator._intl.previousPageLabel = 'Página anterior';
    this.paginator._intl.lastPageLabel = 'Ultima página';
    this.paginator._intl.previousPageLabel = 'Página anterior';

    this.paginator._intl.getRangeLabel = function (page, pageSize, length) {
      if (length === 0 || pageSize === 0) {
        return '0 ' + 'de' + ' ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return 'Mostrando registros del ' + Number(startIndex + 1) + ' al ' + endIndex + ' ' + 'de un total de ' + ' ' + length + ' registros';
    };
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  openCity(name) {
    this.descripcion = name

  }

  openTab(name) {
    this.evento = name
  }

  exportToExcelParticipantes() {
    let newArray: any[] = [];
    let data = Object.values(this.participantes.data);
    Object.keys(data).forEach((key, index) => {
      newArray.push({
        "Código": data[key].id,
        "Número de identificación": data[key].dni,
        "Nombre": data[key].primer_nombre,
        "Apellido": data[key].primer_apellido,

      });
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(newArray);

    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    let date = new Date();

    xlsx.writeFile(
      wb,

      "COLMAYOR_APP_MIBIENESTAR_PARTICIPANTES_" + date.getDate() + "_" + (date.getMonth() + 1) + "_" + date.getFullYear() + ".xlsx"
    );
  }


  notificarParticipante(participante) {

    //console.log(this.data)
    this.eventoService.notificarUsuario(this.data.id, participante.dni,this.form.controls.mensaje.value).subscribe(resp => {

      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.closeModal()
        this.obtenerEvento()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  notificarParticipantes() {
    this.eventoService.notificarUsuarios(this.data.id,this.form.controls.mensaje.value).subscribe(resp => {

      if (resp.success) {
        this.alertService.crearMensaje("success", resp.message)
        this.closeModal()

        this.obtenerEvento()
      } else {
        this.alertService.crearMensaje("error", resp.message)
      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  obtenerEstadoEventosFull() {
    this.estadoEventosService.obtenerEstadoEventos().subscribe(resp => {
      if (resp.success) {
        this.estadoEventosFull = resp.data
      } else {
        this.alertService.crearMensaje("error", resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }


  obtenerPermisoEstado() {
    let aux = this.estadoEventosFull.find(x => x.estado === this.data.estados[this.data.estados.length - 1].estado)
    if (aux != undefined) {
      if (aux.campos_editables === undefined || aux.campos_editables === "") {
        return false
      } else {
        return true

      }
    }

  }

  validarVacio(){
    if(this.mensaje.trim()===''){
      return true
    }else{
      return false
    }
  }

  desvincularUsuario(usuario){
    this.eventoService.desvincularUsuario(this.data.id,usuario).subscribe(resp=>{
      if(resp.success){
        this.alertService.crearMensaje("success",resp.message)
        this.verDetalle()
      }else{
        this.alertService.crearMensaje("error",resp.message)

      }
    },error=>{
      this.errorService.notificarError(error.message).subscribe(resp=>{})
    })
  }
}