import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Properties } from '../../../properties';

@Injectable({
  providedIn: 'root'
})
export class CategoriaEventosService {

  constructor(private http:HttpClient,private router:Router) { }
 
  obtenerCategoriaEventosGestion():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/parametros-eventos/obtener-categorias',{ headers: headers })
  }

  obtenerCategoriaEventos():Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });
    return this.http.get(Properties.URL+'api/categorias-evento/listar-categorias-evento',{ headers: headers })
  }
  crearCategoria(categoria):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "categoria":categoria
    }
    return this.http.post(Properties.URL+'api/categorias-evento/crear-categorias-evento',raw,{ headers: headers })
  }

  activarInactivarCategoria(id):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_categoria_evento":id
      }

    return this.http.post(Properties.URL+'api/categorias-evento/activar-inactivar-categorias-evento',raw,{ headers: headers })
  }

  editarCategoria(id,categoria):Observable<any>{
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        "API-NAME": Properties.APPNAME,
        "API-TOKEN": Properties.APITOKEN,
        "API-KEY": Properties.APIKEY,
        "AUTH": localStorage.getItem(Properties.VAR_TOKEN_ADMIN)

      });

      let raw={
        "id_categoria_evento":id,
        "categoria":categoria
      }

    return this.http.post(Properties.URL+'api/categorias-evento/editar-categorias-evento',raw,{ headers: headers })
  }


}
